import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import './frontpage.scss';
import { Contrast, NavigationCard } from '@entur/layout';
import frontpagePhoto from './Entur_frontpage.jpg';
import { LeadParagraph, Heading3, Heading2, Paragraph, Heading1, Link } from '@entur/typography';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ChannelsIcon = makeShortcode("ChannelsIcon");
const CityIcon = makeShortcode("CityIcon");
const ValidationInfoIcon = makeShortcode("ValidationInfoIcon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="frontpage">
    <div className="frontpage__sidebar-left" />
    <div className="frontpage__header">
        <Heading1 className="frontpage__heading" mdxType="Heading1">Entur Developer</Heading1>
        <LeadParagraph className="frontpage__lead-paragraph" mdxType="LeadParagraph">
            Welcome to Entur for developers, a site containing information
            useful for developers.
        </LeadParagraph>
        <div className="frontpage__navigation-cards">
            <NavigationCard title="Overview" className="frontpage__navigation-card" titleIcon={<ChannelsIcon mdxType="ChannelsIcon" />} href="/pages-intro-overview" mdxType="NavigationCard">
                Check out the overview of our open source code and APIs to find
                the resources that suit your needs.
            </NavigationCard>
            <NavigationCard title="Open Data" className="frontpage__navigation-card" titleIcon={<CityIcon mdxType="CityIcon" />} href="/stops-and-timetable-data" mdxType="NavigationCard">
                Part of our mission statement is to share our data with anyone
                who wants it for free.
            </NavigationCard>
            <NavigationCard title="News and Notifications" className="frontpage__navigation-card" titleIcon={<ValidationInfoIcon mdxType="ValidationInfoIcon" />} href="/pages-intro-news" mdxType="NavigationCard">
                An overview of our API Changelogs, our open plans, and latest
                changes.
            </NavigationCard>
        </div>
    </div>
    <div className="frontpage__sidebar-right" />
    <div className="frontpage__main">
        <div className="frontpage__info">
            <div>
                <Heading2 mdxType="Heading2">About Entur</Heading2>
                <Paragraph mdxType="Paragraph">
                    Entur operates the national registry for all public
                    transport in Norway, collecting data from 60 public
                    transportation operators. The registry contains data about
                    21,000 daily departures on 3,000 routes. This data is open
                    and free of charge for app and service developers.
                </Paragraph>
                <Paragraph mdxType="Paragraph">
                    Read more about Entur on{' '}
                    <Link href="https://www.entur.org" mdxType="Link">entur.org</Link>. Are you
                    interested in open positions, see{' '}
                    <Link href="https://om.entur.no/jobb-i-entur/" mdxType="Link">
                        jobb-i-entur
                    </Link>
                    .
                </Paragraph>
            </div>
            <div>
                <Heading2 mdxType="Heading2">Questions?</Heading2>
                <Paragraph mdxType="Paragraph">
                    Want help to find the things you're looking for? Or do you
                    want to help us in improving this site? Send an email to{' '}
                    <Link href="mailto:kollektivdata@entur.org" mdxType="Link">
                        kollektivdata@entur.org
                    </Link>
                    .
                </Paragraph>
                <Paragraph mdxType="Paragraph">
                    We use cookies on this site for analytics, which helps us
                    improve the quality of this developer portal.
                </Paragraph>
            </div>
        </div>
        <div className="frontpage__photo">
            <img src={frontpagePhoto} alt="frontpage Entur" style={{
            width: '100%'
          }} />
        </div>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      